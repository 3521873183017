import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalOffices from "../components/globalOffices"
import ImageTween from "../components/imageTween"
import Lightbox from "../components/Lightbox/Lightbox"

const Retail = () => {
  const lang = "en"

  return (
    <Layout lang={lang} translation="/pl/lokale/">
      <SEO title="Retail" />

      <section className="home-top">
        <div className="global__padd">
          <div className="home-top__wrapper">
            <h2>
              UNITY <br /> <span>Centre</span>
            </h2>
            <h2>Krakow's Business Complex</h2>
          </div>
          <ImageTween src={require("../assets/images/_I8A8396.jpg")} alt="" />
        </div>
      </section>

      <section className="retail-top global__padd--bigger">
        <h2>A good stretch</h2>
        <p>
          Nothing can create a sense of place quite like a lively neighborhood
          populated with shops, cafes, and kiosks. It boosts the economy, brings
          people together and offers entrepreneurs a space to start their own
          businesses. Times are changing - and so is the nature of the retail
          and hospitality industry - but the need for a space to showcase wares,
          nurture interaction and curiosity will never fade. These stretches can
          benefit the entire community.
        </p>
      </section>

      <section className="retail-content global__padd--bigger">
        <div className="row no-gutters align-items-center">
          <div className="col-lg-7">
            <div className="retail-content__inner sticky-wrapper">
              <h2>UNITY Square</h2>
              <p>
                Plentiful, accessible and exciting public space is a fundamental
                element of urban life. The central part of the complex
                encourages people to spend more time outdoors in restaurants,
                cafes, exhibition areas, service points and green spots. Your
                next destination at the fingertips.
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <ImageTween
              src={require("../assets/images/retail-content-image-1.jpg")}
              srcSet={require("../assets/images/retail-content-image-1@2x.jpg")}
              alt=""
            />
          </div>
        </div>

        <div className="row no-gutters flex-column-reverse flex-md-row align-items-center">
          <div className="col-lg-5">
            <ImageTween
              src={require("../assets/images/retail-content-image-2.jpg")}
              srcSet={require("../assets/images/retail-content-image-2@2x.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <div className="retail-content__inner sticky-wrapper">
              <h2>UNITY Retail</h2>
              <p>
                We are able to provide you with a vibrant link between great
                location and your customers, offering a range of retail and
                hospitality spaces for discerning urban tenants. It is an ideal
                space for a flagship store, kiosk, restaurant, beauty salon or
                flower shop. The UNITY CENTRE complex will ensure high foot
                traffic and a regular clientele.
              </p>
              <div className="retail-content__inner-action">
                <Lightbox
                  ctaType="button"
                  ctaText="See our retail units"
                  ctaClass="btn-custom-back btn-custom-back--orange"
                  imageSrc={require("../assets/images/retail-content-image-retail-units-en-v2.jpg")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row no-gutters align-items-center">
          <div className="col-lg-7">
            <div className="retail-content__inner sticky-wrapper">
              <h2>UNITY Hotel</h2>
              <p>
                Radisson RED Hotel is a vibrant place that combines music and
                creativity in an innovative way. Right from the entrance to the
                hotel lobby, the open atrium with panoramic elevators and a
                local accent - a red sculpture of the Wawel dragon in the RED
                style - make a huge impression. The hotel offers 229 carefully
                decorated rooms, a conference floor and a fitness area.
                Additionally, the hotel's Filini Restaurant & Bar invites you on
                a culinary journey through Italy.
              </p>
              <p>
                This four-star hotel stands out for its fresh approach to
                traditional hospitality, combining stylish interiors with an
                energetic atmosphere.
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <ImageTween
              src={require("../assets/images/PG_240619_304.jpg")}
              alt=""
            />
          </div>
        </div>

        <div className="row no-gutters flex-column-reverse flex-md-row align-items-center">
          <div className="col-lg-5">
            <ImageTween
              src={require("../assets/images/PG_240619_119.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <div className="retail-content__inner sticky-wrapper">
              <h2>UNITY Residence</h2>
              <p>
                Radisson RED Apartments offers 46 spacious apartments for
                long-term stays. Guests can choose from studio or separate
                bedroom apartments, which are equipped with kitchenettes. Most
                of these apartments also have a covered terrace, allowing for
                outdoor relaxation.
              </p>
              <p>
                A bold design that combines modern elements and high-level
                amenities provides an exceptional experience during your stay.
                The apartments have been designed in accordance with the hotel
                standard, offering everything you need for comfortable and
                long-term accommodation.
              </p>
            </div>
          </div>
        </div>
      </section>

      <GlobalOffices lang={lang} retail />
    </Layout>
  )
}

export default Retail
